import { isEmpty } from "@/utils";
import { eventNames } from "@/model/common/events/eventConst";

export const alertableErrorMixin = {
  components: {
    BaseAlert: () => import("@/components/shared/base/BaseAlert")
  },
  computed: {
    showAlert: {
      get() {
        return !isEmpty(this.error);
      },
      set(value) {
        this.$emit(eventNames.alertVisibilityChanged, value);
      }
    }
  },
  props: {
    error: {
      type: String,
      default: ""
    },
    dismissibleAlert: {
      type: Boolean,
      default: true
    }
  }
};
