<template>
  <v-card>
    <base-alert v-model="showAlert" :dismissible="dismissibleAlert">{{
      error
    }}</base-alert>

    <v-card style="margin-top: 10px;">
      <!-- Toolbar -->
      <v-card-title v-if="hasSlot('toolbar')">
        <slot name="toolbar"></slot>
      </v-card-title>

      <!-- Search -->
      <v-toolbar dense flat v-if="hasSlot('search')">
        <slot name="search"></slot>
      </v-toolbar>

      <!-- Data table -->
      <slot name="dataTable"></slot>

      <!-- Snackbar -->
      <slot name="snackbar"></slot>
    </v-card>
  </v-card>
</template>

<script>
// mixins
import { alertableErrorMixin } from "@/mixins/shared/alertable/alertableErrorMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseTableLayout",
  mixins: [alertableErrorMixin, slottableMixin]
};
</script>
